<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="text-center">
          <v-progress-circular :size="80" color="primary" width="3" indeterminate></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$i18n.t("security.logout.title"),
    };
  },
  created() {
    this.logout();
  },
  methods: {
    logout() {
      const self = this;
      this.$auth.logout({
        error: function() {
          self.$router.push({ name: 'login' })
        }
      })
      // disconnect to socket
      this.$socket.disconnect();
    },
  },
};
</script>

